import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/models';


@Injectable({
  providedIn: 'root',

})
export class StorageService {
  private _storage: Storage | null = null;
  public currentUserSubject = new BehaviorSubject<User>(undefined);

  public currentUser: Observable<User>;


  constructor(private storage: Storage, private http: HttpClient) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    var user;
    const storage = await this.storage.create();
    this._storage = storage;
    this.get('currentUser').then(res => {
      //console.log(res);

      if (res) {
        this.getUserFieldsD(res).then(reso => {
          console.log(reso);
          res.fields = reso;
          res.atualizou = true;
          this.storage.set('currentUser', res);
        });

        user = res;
        console.log(user);


        this.currentUserSubject.next(user);
        this.currentUser = this.currentUserSubject.asObservable();
      }


    })

    console.log('storage ok');
  }

  getUser(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  async setUser(key: string, value: any) {
    var usera;
    return await this._storage?.set(key, value).then(res => {
      //console.log(res);
      value.atualizou = true;
      usera = value;
      this.currentUserSubject.next(usera);
      this.currentUser = this.currentUserSubject.asObservable();
      return this.currentUserSubject.value;
    });




  }

  getUserFieldsD(res) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + res.token);
    const options = {
      headers: headers,
    };
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/${environment.apiPaths.usuario}/${res.id}`, options).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public get currentUserValue(): User {
    if (this.currentUserSubject && this.currentUserSubject.value) {
      return this.currentUserSubject.value;
    } else {
      return null;
    }

  }
  // Create and expose methods that users of this service can
  // call, for example:
  public set(key: string, value: any) {
    if (key === 'currentUser') {
      this.getUserFieldsD(value).then(reso => {
        console.log(reso);
        value.fields = reso;
        value.atualizou = true;
        this._storage?.set(key, value);
      });

      let user = value;
      this.currentUserSubject = new BehaviorSubject<User>(user);
      this.currentUser = this.currentUserSubject.asObservable();

    } else {
      this._storage?.set(key, value);
    }

  }
  public async get(key: string) {
    return await this._storage?.get(key);
  }
  public async clear() {
    await this._storage?.clear();
  }
  logout() {
    this.clear();
    if (this.currentUserSubject && this.currentUserSubject.value) {
      this.currentUserSubject.next(null);
    }

  }

}
