import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private currentUser: any = null;
    constructor(
        private router: Router, private api: ApiService,
        private storage: StorageService,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,) {
        return this.storage.get('currentUser').then(res => {
            console.log(res);
            this.currentUser = res;
            this.storage.currentUserSubject.next(res);
            if (this.currentUser) {
                // logged in so return true
                return true;
            } else {
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/login']);
                return false;
            }

        });
        //this.currentUser = this.storage.currentUserValue;


        //const currentUser = this.storage.currentUserSubject.value;

        //console.log(this.currentUser);


    }

}