import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private version;
  public appVersion$ = new BehaviorSubject<string>(null);
  public device_uid$ = new BehaviorSubject<string>(null);
  public devicePlatform$ = new BehaviorSubject<string>(null);
  public mailComunica$ = new BehaviorSubject<string>(null);
  public postId$ = new BehaviorSubject<string>(null);

  private loading: HTMLIonLoadingElement;
  public headerHeightSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public searchItems: any = [];
  public returnDismiss: any;
  private apiUrl = 'https://us-central1-dualpixel-metricas.cloudfunctions.net/register_event';

  constructor(private storage: StorageService,
    private loadingController: LoadingController,
    public toastController: ToastController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private http: HttpClient,
    platform: Platform) {

    this.version = environment.appVersion;

  }

  insertEvento(event, event_name, atributos) {
    let evento = {
      "tipo_evento": event,
      "nome_evento": event_name,
      "login": this.storage.currentUserValue.fields.matricula,
      "empresa": "wide",
      "origem": "app_wide",
      "atributos": atributos,
      "device": {
        "sistema": 'web',
        "versao_app": this.version
      }
    };
    console.log(evento);

    const headers = new HttpHeaders().set('Content-Type', 'application/json');


    this.http.post(this.apiUrl, evento, { headers: headers, responseType: 'text' })
      .subscribe(response => {
        console.log(response);
      });




  }

  async setPostId(res) {
    this.postId$.next(res);

  }
  getData(): Observable<string> {
    return this.postId$;
  }
  async setAppVersion(res) {
    this.appVersion$.next(res);
    //this.appVersion$.next('0.2.8');
  }
  async setMailComun(res) {
    this.mailComunica$.next(res);
    //this.appVersion$.next('0.2.8');
  }

  async setDeviceUid(res) {
    this.device_uid$.next(res);
    //this.device_uid$.next('076C98D1-0A34-45F6-8D94-3C0F662305sdadas43');
  }

  async setDevicePlatform(res) {
    this.devicePlatform$.next(res);
  }

  async verifyUID(device_uid) {
    if (device_uid === this.device_uid$.value) {
      return true;
    } else {
      return false;
    }

  }

  async showLoading() {
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        cssClass: 'carregando',
        message: 'Carregando...',
        spinner: "crescent",
        translucent: true,
      });
      await this.loading.present();
    }
  }

  async showLoadingM(messagem) {
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        cssClass: 'carregando',
        message: messagem,
      });
      await this.loading.present();
    }
  }

  async sendSearchItems(posts) {
    this.searchItems = posts;
  }

  async getSearchItems() {
    return this.searchItems;
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'middle',
      cssClass: 'toast-contact',
      buttons: [
        {
          text: ' x ',
          side: 'end',
          role: 'cancel',
          handler: () => {
            //console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  async hideLoading() {
    this.loadingController.dismiss()
    console.log('fechou o loading')
    /* const load = await this.loadingController.getTop();
     if (load) {
       load.dismiss();
       this.loading = null;
     }*/
  }

  async dismissModal(data) {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      modal.dismiss();
    }



  }
  async returnDataDismiss() {
    return new Promise<any>((resolve, reject) => {
      resolve(this.returnDismiss);
    });

  }


  async openSingle(post: any, color: string, component) {
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: {
        post: post,
        color
      },
      showBackdrop: false,
    });
    modal.onDidDismiss()
      .then((data) => {
        this.returnDismiss = data['data'];
      });

    return await modal.present();
  }

  async openSingleI(post: any, color: string, audioIndex: any, component) {
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: {
        post: post,
        color,
        audioIndex: audioIndex
      },
      showBackdrop: true,
    });
    return await modal.present();
  }

  downloadFile(url, extensao) {
    return null;
  }

  getFilename(url) {
    if (url) {
      var m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1) {
        return m[1] + ".";
      }
    }
    return "";
  }






}
