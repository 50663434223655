// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: '0.0.1',
  apiUrl: 'https://app-wide.wideds.com.br/wp-json/wide/v1',
  apiAuthUrl: 'https://app-wide.wideds.com.br/wp-json/jwt-auth/v1/token',
  apiPaths: {
    noticias: 'noticias',
    noticia: 'noticia',
    avisos: 'avisos',
    videos: 'videos',
    podcasts: 'podcasts',
    enquetes: 'enquetes',
    insertComment: 'insert-comment',
    insertLike: 'insert-like',
    reloadLikes: 'reload-likes',
    reloadComments: 'reload-comments',
    countFavorite: 'insert-fav',
    insertVisu: 'insert-visu',
    validaUser: 'valida-user',
    smsValidaUser: 'sms-valida-user',
    updateUser: 'update-user',
    votarEnquete: 'votar-enquete',
    checarEnquete: 'checar-votacao-enquete',
    sendForm: 'send-form',
    podCategorias: 'get-categorias',
    deleteComment: 'delete-comment',
    ultimosPosts: 'recent-posts',
    resetSenhaFone: 'esqueceu-senha-confirma-telefone',
    resetSenha: 'esqueceu-senha',
    usuario: 'usuario',
    updateUserNew: 'update-user-new',
    updateUserField: 'update-user-field',
    uploadImage: 'upload-image',
    updateUserPerfil: 'update-user-perfil',
    getCasts: 'casts',
    getVideosPage: 'videos-page',
    verifyAppVersion: 'app-version',
    opcoes: 'options',
    lives: 'lives',
    aovivo: 'aovivo',
    busca: 'search'
  },
  firebaseConfig: {
    apiKey: "AIzaSyA8lGxsZjfqF3iNw_WhhEC-_kZu58iiwd0",
    authDomain: "app-wide-01.firebaseapp.com",
    projectId: "app-wide-01",
    storageBucket: "app-wide-01.appspot.com",
    messagingSenderId: "55234850665",
    appId: "1:55234850665:web:2be2b2e3725fe6256617f8",
    measurementId: "G-K5X913PWMF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
