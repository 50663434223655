<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu side="end" contentId="main-content" type="overlay">
      <ion-content class="ion-no-padding">
        <div class="menu-header pt-5 pb-3 bg-cinza-full">
          <ion-item color="cinza-full">
            <ion-avatar slot="start" *ngIf="(storage.currentUser | async)?.fields">
              <img [src]="(storage.currentUser | async)?.fields.foto " />

            </ion-avatar>
            <ion-avatar slot="start" *ngIf="!(storage.currentUser | async)?.fields">
              <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y">
            </ion-avatar>
            <ion-label>

              <h3 [innerHTML]="(storage.currentUser | async)?.displayName "></h3>
              <p class="mb-3" [innerHTML]="(storage.currentUser  | async)?.email"></p>
              <ion-menu-toggle auto-hide="false">
                <a class='text-white' [routerLink]="perfilPage" routerDirection="root"><b>Ver meu perfil <span
                      class="text-tijolo">></span></b></a>
              </ion-menu-toggle>

            </ion-label>
          </ion-item>
        </div>
        <ion-list id="inbox-list">

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item (click)="selectedIndex = i; eventoGtm(p.title) " routerDirection="root" [routerLink]="[p.url]"
              detail="false" [class.selected]="selectedIndex == i">

              <ion-label color="cinza-medio" class="text-uppercase">{{ p.title }}</ion-label>
              <ion-icon color="tijolo" slot="end" name="chevron-forward-outline"></ion-icon>

            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="logout()" class="font-weight-bold" routerDirection="root" detail="false">
              <ion-icon color="tijolo" slot="start" name="exit-outline"></ion-icon>
              <ion-label color="tijolo" class="text-uppercase">Sair</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>