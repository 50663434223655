import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SecurityService } from './security.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private securityService: SecurityService,
        private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(resp => {
            return resp.pipe(
                map((res: any) => {
                    if (res.body?.statusCode === 403 && res.body?.code !== 'incorrect_password') {
                        console.error('error 403');
                        this.securityService.logout();
                        this.router.navigate(['']).then(() => location.reload());
                        const error = res.message;
                        throw (error);
                    }
                    return res;
                }));
        });
    }
}