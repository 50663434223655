import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonMenu, ModalController, NavController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { AvisosPage } from './main-content/avisos/avisos.page';
import { ContatoPage } from './main-content/contato/contato.page';
import { EnquetePage } from './main-content/enquete/enquete.page';
import { NoticiasPage } from './main-content/noticias/noticias.page';
import { PodcastPage } from './main-content/podcast/podcast.page';
import { TermosPage } from './main-content/termos/termos.page';
import { VideosPage } from './main-content/videos/videos.page';
import { User } from './models/models';
import { StorageService } from './services/storage.service';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [StorageService]
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public user$: Observable<User>;

  @ViewChild('menu') menu: IonMenu;
  @ViewChild(Nav) navChild: NavController;

  public codigo: any;
  public perfilPage = '/content/perfil';
  public appPages = [
    {
      title: 'Notícias',
      url: '/content/noticias',
    },
    {
      title: 'Avisos',
      url: '/content/avisos',
    },
    {
      title: 'Vídeos',
      url: '/content/videos',
    },
    {
      title: 'Podcasts',
      url: '/content/podcast',
    },
    {
      title: 'Enquetes',
      url: '/content/enquete',
    },
    {
      title: 'Contato',
      url: 'content/contato',
    },
    {
      title: 'Termos de Uso',
      url: 'content/termos',
    },
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public apiService: ApiService,
    public utils: UtilsService,
    public storage: StorageService,
    public modalCtrl: ModalController,
  ) {
    this.initializeApp();

    this.router.events.subscribe(async (res) => {
      const top = await this.modalCtrl.getTop();
      if (typeof top !== 'undefined') {
        await this.modalCtrl.dismiss();
      }
    });


  }


  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.utils.setDevicePlatform('web');
      this.utils.setAppVersion(environment.appVersion);
    });

  }


  async ngOnInit() {


  }

  eventoGtm(tela) {
    console.log(tela);
    (<any>window).dataLayer.push({
      'event': 'tela_menu',
      'titulo_tela_menu': tela,
    });
    let atributas = {
      'titulo_post': tela
    }
    this.utils.insertEvento('evento', 'tela_menu', atributas);

  }

  logout() {
    this.storage.logout();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        special: JSON.stringify(true)
      }
    };

    this.router.navigate(['login'], navigationExtras);
    //location.reload();
  }



}
function Nav(Nav: any) {
  throw new Error('Function not implemented.');
}

