import { AuthGuard } from './services/auth.guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'content/noticias',
    pathMatch: 'full'
  }, 
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'content',
    loadChildren: () => import('./main-content/main-content.module').then(m => m.MainContentPageModule),
    canActivate: [AuthGuard]
    
  },
  {
    path: 'content/noticias',
    loadChildren: () => import('./main-content/noticias/noticias.module').then(m => m.NoticiasPageModule)
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { 
 
}
