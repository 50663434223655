import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './../models/models';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',

})
export class SecurityService implements OnInit {

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private router: Router, private storage: StorageService) {

    //this.init();
    this.storage.getUser().subscribe(res => {
      console.log(res);
      this.currentUserSubject = new BehaviorSubject<User>(res);
      this.currentUser = this.currentUserSubject.asObservable();
    })

  }

  async init() {
    var user;
    await this.storage.get('currentUser').then(res => {
      console.log(res);
      if (res != undefined) {

        this.getUserFieldsD(res).then(reso => {
          console.log(reso);
          res.fields = reso;
          res.atualizou = true;
          this.storage.set('currentUser', res);
        });
        user = res;
        this.currentUserSubject = new BehaviorSubject<User>(user);
        this.currentUser = this.currentUserSubject.asObservable();

      } else {
        //this.storage.clear();
        // this.router.navigate(['login']);
        console.log('não achou user no security')
      }



    });
  }
  ngOnInit() {

  }

  public get currentUserValue(): User {
    if (this.currentUserSubject && this.currentUserSubject.value) {
      return this.currentUserSubject.value;
    } else {
      return null;
    }

  }
  getUserFieldsD(res) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + res.token);
    const options = {
      headers: headers,
    };
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/${environment.apiPaths.usuario}/${res.id}`, options).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getUserFields(id) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/${environment.apiPaths.usuario}/${id}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
  async updateUserField(key, value, login) {
    this.http.post<any>(`${environment.apiUrl}/${environment.apiPaths.updateUserField}`, { field: key, value: value, login: login }).subscribe(data => {
      return data;
    })
  }

  uploadImage(files): Observable<any> {

    const headers = new HttpHeaders().set('Accept', 'application/json');
    const options = {
      headers: headers,
    };

    let url = `${environment.apiUrl}/${environment.apiPaths.uploadImage}`;
    let formData: FormData = new FormData();

    for (let file of files) {
      formData.append('files', file, file.name);
    }
    formData.append('userLogin', this.currentUserValue.fields.matricula);
    return this.http.post(url, formData, options);

  }


  validaUser(username) {
    return this.http.post<any>(`${environment.apiUrl}/${environment.apiPaths.validaUser}`, { username: username });
  }
  validaSmsUser(username, telefone) {
    return this.http.post<any>(`${environment.apiUrl}/${environment.apiPaths.smsValidaUser}`, { username, telefone });
  }

  cadastrarUser(cadastroForm) {
    return this.http.post<any>(`${environment.apiUrl}/${environment.apiPaths.updateUserNew}`, cadastroForm);
  }

  updateUserPerfil(cadastroForm) {
    return this.http.post<any>(`${environment.apiUrl}/${environment.apiPaths.updateUserPerfil}`, cadastroForm);
  }
  async renovaSenha() {
    console.log(this.currentUserValue.fields.mudou_senha);
    if (this.currentUserValue && this.currentUserValue.fields && this.currentUserValue.fields.mudou_senha) {

      let currentDate = new Date();
      let data = moment(this.currentUserValue.fields.mudou_senha, "DD/MM/YYYY hh:mm");

      let dateSent = new Date(data.format("YYYY-MM-DD"));
      let resto = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24))
      if (resto > 90) {
        console.log('mudar senha ' + resto + ' dias');
        return true;
      } else {
        console.log('não mudar senha ' + resto + ' dias');
        return false;
      }

    } else {
      return false;
    }


  }


  makeLogin(userData: { username: string, password: string }) {
    return this.http.post<any>(environment.apiAuthUrl, userData)
      .pipe(map(resp => {
        if (resp) {
          if (resp.statusCode === 200 && resp.success === true) {
            const user = resp.data as User;

            this.currentUserSubject = new BehaviorSubject<User>(user);
            this.currentUser = this.currentUserSubject.asObservable();
            return resp.data as User;
          } else {
            throw (new Error(resp.message));
          }
        } else {
          throw (new Error('Erro desconhecido.'));
        }
      }, (err) => {
        throw (new Error(err));
      }));

  }

  logout() {
    this.storage.clear();
    if (this.currentUserSubject && this.currentUserSubject.value) {
      this.currentUserSubject.next(null);
    }

  }




}
