
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface Api {
  status: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiPath = environment.apiUrl;
  private podCategoriaPath = environment.apiPaths.podCategorias;
  private castsPath = environment.apiPaths.getCasts;
  private appVersion = environment.apiPaths.verifyAppVersion;
  public showBanner = false;
  private options = environment.apiPaths.opcoes;

  constructor(private http: HttpClient) { }

  requestPosts(path: string, page: number) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${path}/${page}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }
  requestPost(path: string, id: any) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${path}/${id}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }

  requestSearch(s: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${environment.apiPaths.busca}`, { params: { termo: s } }).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }

  verifyAppVersion() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${this.appVersion}`).subscribe(res => {
        console.log(res);
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }

  appOptions() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${this.options}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }

  getPodCategories(postType: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${this.podCategoriaPath}/${postType}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }

  getCastsPage(termId, page) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${this.castsPath}/${termId}/${page}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }


  insertComment(postId, comment, parentID) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `postid=${postId}&comment=${comment}`;
    if (parentID) {
      body = `${body}&parent=${parentID}`
    }
    let url = `${this.apiPath}/${environment.apiPaths.insertComment}`;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {

          resolve(response);
        }, err => {
          reject(err);
        })
    });
  }

  deleteComment(comentID) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `comentId=${comentID}`;
    let url = `${this.apiPath}/${environment.apiPaths.deleteComment}`;
    return new Promise(resolve => {
      this.http.post(url, body, { headers: headers })
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  getRecentPosts() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${environment.apiPaths.ultimosPosts}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  insertLike(postId, parentID) {
    let comment = "like";
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `postid=${postId}&comment=${comment}`;
    if (parentID) {
      body = `${body}&parent=${parentID}`
    }
    let url = `${this.apiPath}/${environment.apiPaths.insertLike}`;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {

          resolve(response);
        }, err => {
          reject(err);
        })
    });

  }
  resetSenha(username) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `username=${username}`;
    let url = `${this.apiPath}/${environment.apiPaths.resetSenha}`;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {

          resolve(response);
        }, err => {
          reject(err);
        });
    });



  }

  resetSenhaFone(username, telefone) {

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `username=${username}&telefone=${telefone}`;
    let url = `${this.apiPath}/${environment.apiPaths.resetSenhaFone}`;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {

          resolve(response);
        }, err => {
          reject(err);
        });
    });

  }

  insertFav(postId, parentID) {
    let comment = "favorite";
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `postid=${postId}&comment=${comment}`;
    if (parentID) {
      body = `${body}&parent=${parentID}`
    }
    let url = `${this.apiPath}/${environment.apiPaths.countFavorite}`;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {

          resolve(response);
        }, err => {
          reject(err);
        })
    });

  }

  insertVisu(postId, parentID) {
    let comment = "visualizou";
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `postid=${postId}&comment=${comment}`;
    if (parentID) {
      body = `${body}&parent=${parentID}`
    }
    let url = `${this.apiPath}/${environment.apiPaths.insertVisu}`;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {

          resolve(response);
        }, err => {
          reject(err);
        })
    });

  }

  checarVotacaoEnquete(postId) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `post_id=${postId}`;
    let url = `${this.apiPath}/${environment.apiPaths.checarEnquete}`;
    return new Promise(resolve => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {
          resolve(response);
        })
    });
  }

  votarEnquete(postId, respostas) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `post_id=${postId}&respostas=${JSON.stringify(respostas)}`;
    let url = `${this.apiPath}/${environment.apiPaths.votarEnquete}`;
    return new Promise(resolve => {
      this.http.post<any>(url, body, { headers: headers })
        .subscribe(response => {
          resolve(response);
        })
    });
  }

  sendForm(email, assunto, form) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let body = `form=${JSON.stringify(form)}&email=${email}&assunto=${assunto}`;
    let url = `${this.apiPath}/${environment.apiPaths.sendForm}`;
    return new Promise(resolve => {
      this.http.post(url, body, { headers: headers })
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  reloadLikes(postId) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${environment.apiPaths.reloadLikes}/${postId}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });

  }
  reloadComments(postId) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${this.apiPath}/${environment.apiPaths.reloadComments}/${postId}`).subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      })
    });
  }



}

